import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    obamaPortalScreens: Core.ImageAttributes;
    portalContainer: Core.ImageAttributes;
    kidsMilwaukeePortal: Core.ImageAttributes;
    jonahBokaer: Core.ImageAttributes;
    inflatablePortal: Core.ImageAttributes;
    amarBakshi: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const {
    obamaPortalScreens,
    portalContainer,
    kidsMilwaukeePortal,
    jonahBokaer,
    inflatablePortal,
    amarBakshi
  } = props.data;

  return (
    <Layout title="Portals to Connection">
      <Heading />
      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={amarBakshi}
              alt="Amar Bakshi portrait"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <Core.LargeText>
            Amar Bakshi ’13 has created a model for civic discourse in a global
            climate in which walls, not bridges, drive political rhetoric.
          </Core.LargeText>
          <br />
          <p>
            <Core.LeadIn>Bakshi is testing</Core.LeadIn> the proposition that
            the world would be a better place if we could talk directly to each
            other. One gold shipping container at a time.
          </p>
          <p>
            Walk into one of the golden “Portals” created by Bakshi and his team
            at Brooklyn-based Shared_Studios, and you could find yourself in
            conversation with people in Myanmar, Honduras, or Iraq in a similar
            Portal. You communicate with them not through a narrow computer
            monitor or smartphone, but in an immersive audiovisual environment
            with a ceiling-to-floor video screen.
          </p>
          <p>
            “You can really focus your attention on another human being, who is
            in an identical container—see the person in front of you,” says
            Bakshi, who is Shared_Studios’ founder and creative director.
          </p>
        </Core.ContentSection>
        <Core.FullWidthImage
          image={obamaPortalScreens}
          alt="President Obama speaking through portal screens"
          caption="President Barack Obama speaking to entrepreneurs in South Korea, Iraq,
          Mexico, and the U.K. through Portal screens."
        />
        <Core.ContentSection>
          <p>
            These global dialogues in real time and true proportion are offering
            people across the globe a chance to speak to each other in a new
            sort of public square. Johns Hopkins University is a project
            partner, and the university’s Stavros Niarchos Foundation Agora
            Institute brought a Portal to the Homewood campus last June.
          </p>
          <p>
            “Barriers to communication seem to be erected at every turn,” says
            Elizabeth Smyth, advisor to the president on the Stavros Niarchos
            Foundation Agora Institute. “The Portals serve to bring them down.
            They demonstrate that technology can be used to create connection.”
          </p>
          <p>
            The Portals project pulls together a number of strands in Bakshi’s
            wide-ranging career. In addition to graduating from SAIS with an MA
            in international affairs and international economics, Bakshi has
            earned a law degree from Yale University, worked as a journalist at{' '}
            <em>The Washington Post</em> and CNN, and served as an assistant to
            former U.S. Ambassador to the United Nations Susan Rice.
          </p>
        </Core.ContentSection>
        <Core.ContentSection
          marginalia={
            <>
              <div style={{ marginTop: '2em' }} />
              <Core.Blockquote author="Amar Bakshi" authorTitle=" ’13">
                Art is a domain where the ends are not given. The Portals are
                not for a particular predefined purpose. They’re not designed to
                advance diplomacy or sell widgets.
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={portalContainer}
            alt="portal container"
            caption="The outdoor Portal_Container."
          />
          <Core.FullWidthImage
            image={kidsMilwaukeePortal}
            alt="kids playing in portal container in Milwaukee"
            caption={
              <>
                Kids play at the Milwaukee_Portal.{' '}
                <em>Photos courtesy of Shared_Studios</em>.
              </>
            }
          />
          <p>
            Bakshi observes that the education he obtained at SAIS was part of
            his road map to success for this project and other endeavors. “As
            much as my SAIS experience focused on politics and policy,” he says,
            “it didn’t neglect the aspects of story and lived experience at the
            heart of this project.” He adds that fellow SAIS alumni Afshin
            Molavi ’97, a senior fellow at the school’s Foreign Policy
            Institute, and Karim Sadjadpour BO ’02, a senior fellow at the
            Carnegie Endowment for International Peace, have supported him over
            the years with the Portals and other projects.
          </p>
        </Core.ContentSection>
        <br />
        <Core.ContentSection
          marginalia={
            <Core.Blockquote
              author="Elizabeth Smyth"
              authorTitle=", adviser to the president on the Stavros Niarchos Foundation Agora Institute"
            >
              We think of civil discourse as formal or political. The Portals
              show that we need to think more broadly about what it is and where
              it can happen.
            </Core.Blockquote>
          }
        >
          <p>
            The Portals possess a strong aesthetic sensibility, rooted in their
            creation in 2014 as a public art project decoupled from the worlds
            of consulates and commerce. Art’s capacity to be understood broadly
            across cultures has informed the project’s conception and aims.
          </p>
          <p>
            “We still think of ourselves as building global public art,” says
            Bakshi. “Art is a domain where the ends are not given. The Portals
            are not for a particular predefined purpose. They’re not designed to
            advance diplomacy or sell widgets.”
          </p>
          <p>
            Bakshi says a key aim of the project is “curating human diversity.”
            Institutions around the world subscribe to the Portals network for
            an annual or monthly fee that supports maintenance and improvement
            of the project’s infrastructure and technology, “just as they would
            support a free public library.” Shared_Studios manages the
            technology and helps its partners create Portals in various locales.
          </p>
          <p>
            The company has also created a robust network of curators around the
            Portals to attract this diversity. The technology creates “the
            feeling of being in the same room,” says Mira Bakri, a Portals
            coordinator in Palestine, “and a feeling that the world is
            connected, despite the difficulties facing the Gaza Strip.”
          </p>

          <p>
            Bakri observes that mothers battling cancer in Gaza and the United
            States have connected in Portals to share common struggles.
            Embroiderers from Mexico and Palestine have been able to share and
            compare their work. The chance to examine Mexican crafts through
            immersive technology, she adds, “left me feeling I was in the Disney
            film <em>Coco</em>.”
          </p>
          <p>
            The Portals also create more structured connections, including
            projects that focus on refugee issues and criminal justice. Youseph
            Yazdi, executive director of the Center for Bioengineering
            Innovation and Design at the Johns Hopkins Whiting School of
            Engineering, has deployed them to create collaborative design teams
            and hack-athons with students at American University in Beirut and
            in Gaza City.
          </p>
          <p>
            “I wasn’t sure it was going to work,” says Yazdi, who is also an
            assistant professor of Biomedical Engineering in the Johns Hopkins
            University School of Medicine. But as cross-cultural teams worked to
            tackle issues including health care in humanitarian crises and
            empowering first responders, he observes, “you almost forget the
            technology is there.”
          </p>
          <Core.FullWidthImage
            image={jonahBokaer}
            alt="portal container"
            caption="Choreographer and dancer Jonah Bokaer collaborating with musicians
          in Herat."
          />
          <Core.FullWidthImage
            image={inflatablePortal}
            alt="kids playing in portal container in Milwaukee"
            caption={
              <>
                The Portal_Inflatable.{' '}
                <em>Photos courtesy of Shared_Studios</em>.
              </>
            }
          />
          <p>
            These teams even made joint presentations using the Portals. Yazdi
            says technology enriches these interactions deeply. “It communicates
            information we just don’t get on a screen,” he says. “There are so
            many cues you only get in body language. Even in the way someone is
            dressed.”
          </p>
          <p>
            Bakshi’s project is creating a compelling model for civic discourse
            in a global climate in which walls, not bridges, drive political
            rhetoric and the power of social media is weaponized to sow
            division.
          </p>
          <p>
            “We think of civil discourse as formal or political,” says Smyth.
            “The Portals show that we need to think more broadly about what it
            is and where it can happen.”
          </p>
          <p>
            The Portals rely on a renewed faith that cyberspace not only
            possesses power to convey our statements but also allows us to make
            a positive difference across the globe.
          </p>
          <p>
            “We don’t have to think of technology as isolating us and narrowing
            our horizons,” says Bakshi. “We can think of it as broadening them.
            We’re thinking deeply about how to pull technology inside out. How
            do we use it to bring us out of our bubbles and into deeper
            awareness of the world?”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    obamaPortalScreens: file(
      relativePath: { regex: $directory }
      name: { eq: "obama-portal-screens" }
    ) {
      ...FullWidthImage
    }
    portalContainer: file(
      relativePath: { regex: $directory }
      name: { eq: "portal-container" }
    ) {
      ...ContentWidthImage
    }
    kidsMilwaukeePortal: file(
      relativePath: { regex: $directory }
      name: { eq: "kids-milwaukee-portal" }
    ) {
      ...ContentWidthImage
    }
    jonahBokaer: file(
      relativePath: { regex: $directory }
      name: { eq: "jonah-bokaer" }
    ) {
      ...ContentWidthImage
    }
    inflatablePortal: file(
      relativePath: { regex: $directory }
      name: { eq: "inflatable-portal" }
    ) {
      ...ContentWidthImage
    }
    amarBakshi: file(
      relativePath: { regex: $directory }
      name: { eq: "amar-bakshi" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default FeatureContent;
