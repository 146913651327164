import './Heading.scss';

import React from 'react';

import * as Core from 'components/core';

const PortalsHeading: React.FC = () => {
  return (
    <Core.Masthead color="light" className="PortalsHeading">
      <Core.ContentWrapper>
        <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        <div className="PortalsHeading-content">
          <div className="PortalsHeading-content-title">
            Portals to Connection
          </div>
          <div className="PortalsHeading-content-byline">By Richard Byrne</div>
        </div>
      </Core.ContentWrapper>
    </Core.Masthead>
  );
};

export default PortalsHeading;
